@import (reference) "../settings.less";

@media (max-width: 1279px) {
    .cms-subarticles-wrapper {
        columns: 2 !important;
    }

    // SEARCH ENGINE
    #search-engine {
        position: fixed;
        top: 0;
        left: 0;
        background-color: white;
        width: 100vw;
        height: 100dvh;
        overflow-y: auto;
        z-index: 1001;
        padding: 80px 30px;
        -webkit-overflow-scrolling: touch;

        &.show,
        &.show-last-phrases {

        }

        &:not(.show) {

            .no-products-info {
                display: none !important;
            }
        }

        &:not(.show-last-phrases) {

            .suggestion-last-phrases {
                display: none;
            }

            &:has(.suggestion-result-products:empty) {

                .no-products-info {
                    display: block;
                }
            }
        }
    }

    #searchbutton {
        position: absolute;
        top: 81px;
        right: 33px;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: @header-color;
        font-size: 20px;
        background-color: white;

        &:after {
            content: '\e80d';
            font-family: icomoon;
        }
    }

    .search-engine-close {
        position: absolute;
        height: 60px;
        display: flex;
        align-items: center;
        color: @header-color;
        top: 0;
        left: 0;
        width: 100%;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 15px;
        cursor: pointer;
        user-select: none;
        gap: 20px;
        border-bottom: 1px solid rgba(0,0,0,.2);
        background-color: @border-color;
        padding: 0 30px;

        &:before {
            content: '\e803';
            font-size: 12px;
            font-family: icomoon;
            font-weight: normal;
        }
    }

    .show-search-engine {

        #search-engine {
            display: block;
        }
    }

    #suggestions {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
        width: 100%;
        box-shadow: none;
        border-radius: 0;
        padding: 0;
        margin-top: 16px;
        overflow-y: auto;
        opacity: 1;
        visibility: visible;

        [data-label] {
            margin-bottom: 20px;
        }
    }

    .suggestion-column-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .suggestion-left-column {
        order: 1;
        width: 100%;
    }

    .suggestion-last-phrases {
        width: 100%;
        order: -1;
        margin-bottom: 20px;

        span {
            font-size: 15px;
            padding: 3px 0;
        }
    }

    a.suggestions-advanced-search {
        margin-top: 0;
    }

    .suggestion-result-products {
        margin-bottom: 20px;
    }

    .comment-input-wrapper {
        margin-bottom: 12px;
    }

    .show-menu {
        overflow: hidden;

        #tree {
            transform: translate3d(0, 0, 0);
        }

        .tree-canvas {
            opacity: .6;
            pointer-events: auto;
        }
    }

    #tree {
        height: 100vh;
        height: 100dvh;
        z-index: 1002;
        background-color: lighten(@border-color, 5%);
        transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
        padding-top: 60px;

        ul:not(.cms-rwd) {
            background-color: white;
        }

        nav {
            position: relative;
            min-width: 300px;
            width: auto;
            height: 100%;
            overflow-x: hidden;
            overflow-y: visible;
            scrollbar-width: none;

            > ul {
                position: relative;

                &.cms-rwd {

                    a {
                        font-weight: normal;
                    }
                }

                &:last-child {
                    padding-bottom: 30px;
                }

                &:not(:empty) {

                    &:before {
                        display: block;
                        padding: 10px 20px;
                    }
                }

                &:nth-of-type(2):not(:empty) {
                    border-top: 1px solid rgba(0,0,0,.2);
                    background-color: lighten(@border-color, 5%);

                    &:before {
                        content: 'Menu';
                    }

                    a {
                        padding-top: 8px;
                        padding-bottom: 8px;
                    }
                }

                &:before {
                    content: 'Kategorie';
                    display: none;
                }

                &.hide {
                    left: -100%;

                    .close-tree-header {
                        display: none;
                    }

                    & + .cms-rwd {
                        display: none;
                    }
                }

                ul {
                    opacity: 0;
                    pointer-events: none;

                    &.active {
                        left: 100%;
                        top: 0;
                        pointer-events: auto;
                        opacity: 1;
                    }

                    &.hide {
                        left: 0;

                        > .category-header {
                            display: none;
                        }
                    }
                }
            }
        }

        ul {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding-top: 10px;
        }

        .parent > a {
            display: flex !important;
            align-items: center;
            justify-content: space-between;

            &:after {
                content: '\e800';
                font-family: icomoon;
                font-weight: normal;
                margin-left: 20px;
                font-size: 10px;
                transform: rotate(-90deg);
            }
        }

        a:not(.tree-category-all-link) {
            padding: 15px 20px;
            color: @header-color;
            display: block;
            font-weight: bold;
            font-size: 14px;
        }

        a.tree-category-all-link {
            display: block;
            color: @header-color;
            padding: 10px 20px;
            text-decoration: underline;
        }

        .tree-header {
            position: fixed;
            height: 60px;
            display: flex;
            align-items: center;
            color: @header-color;
            top: 0;
            left: 0;
            width: 100%;
            font-weight: bold;
            padding: 0 20px;
            text-transform: uppercase;
            font-size: 15px;
            border-bottom: 1px solid rgba(0,0,0,.2);
            cursor: pointer;
            user-select: none;
            transform: translate3d(0,0,0);

            &:before {
                font-family: icomoon;
                font-weight: normal !important;
                font-size: 15px;
                display: flex;
                align-items: center;
                margin-right: 20px;
            }

            &.close-tree-header:before {
                content: '\e803';
                font-size: 12px;
            }

            &.category-header:before {
                content: '\e800';
                transform: rotate(90deg);
            }
        }

        button,
        small {
            display: none;
        }
    }
}

@media (max-width: 1279px) {
    // OTHER
    .form-modal .goshop-modal {
        width: 90vw;
        max-width: none;
    }

    .availability-modal-inner {

        .img-wrapper {
            display: none;
        }
    }

    .newsletter-cookie-modal {

        .img-wrapper {
            display: none;
        }

        .text-wrapper {
            padding: 30px 10px;
        }
    }
}


@media (max-width: 600px) {
    .tippy-box .tippy-content {
        padding: 10px;
    }

    .infobox {
        padding: 10px;

        > * {
            width: 100%;
        }

        &:before {
            font-size: 80px;
        }
    }

    .goshop-modal-header {
        font-size: 16px;
        padding-right: 50px;
        line-height: 1.2;
        height: 50px;
        padding-left: 15px;

        &:before {
        }
    }

    .goshop-modal-close {
        font-size: 14px;
        width: 50px;
        height: 50px;
    }

    .cart-modal .goshop-modal-body {

        .cart-popup-row-wrapper {
            flex-direction: column;
            align-items: center;
        }

        figure {
            height: 80px;
            margin: 0 0 10px;
            width: 100%;
        }

        section {
            text-align: center;
        
            figure {
                width: 100%;
                height: 80px;
                margin: 0 0 10px;
            }

            > div {
                width: 100%;

                > strong {
                    font-size: 16px;
                }
            }
        }

        .goshop-modal-footer {
            gap: 10px;

            > * {
                width: 100%;
                text-align: center;

                &:nth-child(2) {
                    order: -1;
                }
            }
        }
    }

    .cms-subarticles-wrapper {
        columns: 1 !important;
    }
}
